import React, { useState, useEffect } from "react";
import { useAuth } from "../AuthContext";

import { useNavigate, useLocation } from "react-router-dom";
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Box,
  Flex,
  Heading,
  Button,
  SimpleGrid,
  Stat,
  VStack,
  StatLabel,
  StatNumber,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  useDisclosure,
  IconButton,
  TableContainer,
  useColorModeValue,
} from "@chakra-ui/react";
import { HamburgerIcon, CloseIcon } from "@chakra-ui/icons";

const DeviceInfoPage = ({ isSidebarOpen, setSidebarOpen }) => {
  console.log("issidebar open" + isSidebarOpen);

  const { logout } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const [devices, setDevices] = useState([]);
  const [activeDevice, setActiveDevice] = useState(null);
  const [type, setType] = useState("");
  const custid = localStorage.getItem("custid");
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `https://aygqbopt0e.execute-api.us-east-2.amazonaws.com/getLatestData?custid=${custid}&type=${type}`
        );
        const data = await response.json();

        setDevices(data.devices);
      } catch (error) {
        console.error("Failed to fetch:", error);
      }
    };

    if (custid && type) {
      fetchData();
      const interval = setInterval(fetchData, 5000); // Fetch data every 5 seconds
      return () => clearInterval(interval); // Cleanup the interval on component unmount
    }
  }, [custid, type]);

  const formatTimestamp = (timestamp) => {
    const date = new Date(timestamp);
    return date.toLocaleString();
  };

  const handleNavigate = (deviceType) => {
    setActiveDevice(deviceType);
    navigate(`/devices/${deviceType}`);
  };

  const handleLogout = async () => {
    await logout();
    navigate("/login");
  };

  const isActive = (path) => location.pathname === path;
  const renderTableHeader = (data) => {
    // Get all unique keys from the data array
    const allKeys = data.reduce((keys, item) => {
      Object.keys(item.data).forEach((key) => {
        keys.add(key);
      });
      return keys;
    }, new Set());

    // Convert the Set of keys to an Array for mapping
    // const headerKeys = Array.from(allKeys);
    const headerKeys = Array.from(allKeys).filter(
      (key) => key !== "devicetype"
    );

    return (
      <Thead>
        <Tr>
          <Th>Device Type</Th>
          <Th>IMEI</Th>
          {headerKeys.map((header) => (
            <Th key={header}>{header.toUpperCase()}</Th>
          ))}
          <Th>Last Updated</Th>
        </Tr>
      </Thead>
    );
  };

  const renderTableBody = (data) => {
    return (
      <Tbody>
        {data.map((device, index) => (
          <Tr key={index}>
            <Td>{device.imei}</Td>
            {Object.entries(device.data).map(
              ([key, value]) =>
                // Ensure 'devicetype' is not rendered as part of the data rows
                key !== "devicetype" && <Td key={key}>{value}</Td>
            )}
            <Td>{new Date(device.timestamp).toLocaleString()}</Td>
          </Tr>
        ))}
      </Tbody>
    );
  };

  return (
    <Flex
      direction="column"
      m="5"
      md="50px"
      ml="350px"
      //   m={isSidebarOpen ? "5" : { base: "5", md: "0" }} // Adjust margin if sidebar is open
      p={5}
      flex="1"
      bg="gray.100"
      //   ml={isSidebarOpen ? { md: "50px" } : "0"} // Adjust left margin on medium screens and above
      overflowX="auto"
    >
      <Box mb={10}>
        <Heading as="h2" size="lg" mb={6}>
          Devices Information
        </Heading>
      </Box>

      <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10}>
        {/* Buttons for devices */}
        <Stat
          p={5}
          bg="teal.500"
          color="white"
          borderRadius="md"
          boxShadow="base"
          _hover={{ bg: "teal.600" }}
          transition="background 0.3s"
          // onClick={() => handleNavigate("bpmeter")}
          onClick={() => setType("bpmeter")}
          cursor="pointer"
        >
          <StatNumber textAlign="center">MTM BPM</StatNumber>
          <StatLabel textAlign="center">View Details</StatLabel>
        </Stat>

        <Stat
          p={5}
          bg="orange.500"
          color="white"
          borderRadius="md"
          boxShadow="base"
          _hover={{ bg: "orange.600" }}
          transition="background 0.3s"
          // onClick={() => handleNavigate("oximeter")}
          onClick={() => setType("oximeter")}
          cursor="pointer"
        >
          <StatNumber textAlign="center">MTM Pulse Oximeter</StatNumber>
          <StatLabel textAlign="center">View Details</StatLabel>
        </Stat>
      </SimpleGrid>
      <Heading p={4} mb={4}>
        {type === "bpmeter"
          ? "MTM BPM (" + devices.length + " devices)"
          : type === "oximeter"
          ? "MTM Pulse Oximeter (" + devices.length + " devices)"
          : type.charAt(0).toUpperCase() + type.slice(1)}
        {""}
      </Heading>
      <br></br>
      <Accordion allowToggle>
        {devices.map((device, index) => (
          <AccordionItem key={index}>
            <h2>
              <AccordionButton>
                <Box flex="1" textAlign="left">
                  IMEI: {device.imei} - Last updated:{" "}
                  {formatTimestamp(device.timestamp)}
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              <Table variant="simple">
                <Thead>
                  <Tr>
                    {Object.keys(device.data).map((key) => (
                      <Th key={key}>{key.toUpperCase()}</Th>
                    ))}
                  </Tr>
                </Thead>
                <Tbody>
                  <Tr>
                    {Object.values(device.data).map((value, index) => (
                      <Td key={index}>
                        {typeof value === "object"
                          ? JSON.stringify(value)
                          : value}
                      </Td>
                    ))}
                  </Tr>
                </Tbody>
              </Table>
            </AccordionPanel>
          </AccordionItem>
        ))}
      </Accordion>
    </Flex>
    //    <Box flex="1" p={5} overflowY="auto">
    //       <Heading mb={6}>Device Information</Heading>
    //       <TableContainer>
    //         <Table variant="striped" colorScheme="teal" size="sm">
    //           {devices.length > 0 && renderTableHeader(devices)}
    //           {renderTableBody(devices)}
    //         </Table>
    //       </TableContainer>
    //     </Box>
  );
};

export default DeviceInfoPage;
