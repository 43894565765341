import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import { useAuth } from "../AuthContext";
import {
  Box,
  Flex,
  Text,
  Heading,
  SimpleGrid,
  Stat,
  StatLabel,
  StatNumber,
  StatGroup,
  Progress,
  Spacer,
  Badge,
  Divider,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Button,
  VStack,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
} from "@chakra-ui/react";
import {
  PieChart,
  Pie,
  Cell,
  ResponsiveContainer,
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";

// Example data for the charts

const dataLine = [
  { name: "Mon", uv: 4000, pv: 2400, amt: 2400 },
  { name: "Tue", uv: 3000, pv: 1398, amt: 2210 },
  { name: "Wed", uv: 2000, pv: 9800, amt: 2290 },
  { name: "Thu", uv: 2780, pv: 3908, amt: 2000 },
];

const COLORS = ["#0088FE", "#FFBB28", "#FF8042"];

const Dashboard = ({ active }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [userInfo, setUserInfo] = useState({
    email: "",
    imeiCount: 0,
    activeDevices: 0,
    inactiveDevices: 0,
    lastUpdated: "",
  });
  const dataPie = [
    { name: "Active Devices", value: userInfo.activeDevices },
    { name: "Inactive Devices", value: userInfo.inactiveDevices },
  ];
  const isActive = (path) => location.pathname === path;
  const { logout } = useAuth();
  const [type, setType] = useState("");
  const custid = localStorage.getItem("custid");

  useEffect(() => {
    const fetchUserInfo = async () => {
      try {
        const response = await fetch(
          `https://aygqbopt0e.execute-api.us-east-2.amazonaws.com/getLatestData?custid=${custid}`
        );
        const parsedBody = await response.json();
        // const parsedBody = JSON.parse(data.body); // Parsing the stringified JSON in the body

        const lastUpdatedTime = new Date();
        // Count active and inactive devices
        let activeDevices = 0;
        let inactiveDevices = 0;
        const oneDayAgo = new Date();
        oneDayAgo.setDate(oneDayAgo.getDate() - 2); // Set to 2 day ago

        parsedBody.devices.forEach((device) => {
          const deviceTimestamp = new Date(device.timestamp);
          if (deviceTimestamp > oneDayAgo) {
            activeDevices++;
          } else {
            inactiveDevices++;
          }
        });

        setUserInfo({
          email: parsedBody.user,
          imeiCount: parsedBody.devices.length,
          activeDevices: activeDevices,
          inactiveDevices: inactiveDevices,
          lastUpdated: lastUpdatedTime.toLocaleString(),
        });
      } catch (error) {
        console.error("Failed to fetch:", error);
      }
    };

    fetchUserInfo();
  }, [custid]); // Dependency array to re-run the effect when `custid` changes

  const handleLogout = async () => {
    await logout();
    navigate("/login");
  };
  const goToDeviceInfoPage = () => {
    navigate("/device-info"); // Make sure the path matches the one defined in your <Route>
  };
  return (
    <Flex height="100vh" ml="350px">
      {/* <Box
        width={{ base: "full", md: "350px" }}
        p={5}
        color="white"
        bg="blue.500"
      >
        <VStack align="stretch" spacing={5}>
          <Heading mb={8}>Med-Tech Match</Heading>
          <Button
            colorScheme="blue"
            onClick={() => navigate("/dashboard")}
            bg={isActive("/dashboard") ? "blue.700" : "blue.500"}
          >
            Dashboard
          </Button>
          <Button colorScheme="blue" onClick={() => navigate("/device-info")}>
            MTM Devices Info
          </Button>
          <Button colorScheme="blue" onClick={() => navigate("/api")}>
            API Info
          </Button>
          <Button colorScheme="red" onClick={handleLogout}>
            Logout
          </Button>
        </VStack>
      </Box> */}
      <Box flex="1" bg="gray.100" p={10}>
        <Flex alignItems="center">
          <Heading as="h2" size="lg">
            Dashboard
          </Heading>
          <Spacer />
          <Text fontSize="lg">Last Updated: {userInfo.lastUpdated}</Text>
        </Flex>
        <Flex alignItems="center">
          <Heading as="h2" size="lg" mb={4}>
            Welcome, {userInfo.email}
          </Heading>
        </Flex>

        <SimpleGrid columns={3} spacing={10} mt={5}>
          <Stat p={4} bg="teal.100" borderRadius="md">
            <StatLabel>Total MTM Devices</StatLabel>
            <StatNumber>{userInfo.imeiCount} pcs</StatNumber>
          </Stat>
          <Stat p={4} bg="green.100" borderRadius="md">
            <StatLabel>Active Devices in Past 48 hours</StatLabel>
            <StatNumber>{userInfo.activeDevices} pcs</StatNumber>
          </Stat>
          <Stat p={4} bg="red.100" borderRadius="md">
            <StatLabel>Deactivated Devices from 48 hours</StatLabel>
            <StatNumber>{userInfo.inactiveDevices} pcs</StatNumber>
          </Stat>
          {/* <Stat p={4} bg="blue.100" borderRadius="md">
            <StatLabel>Total Traffic Usage</StatLabel>
            <StatNumber>23MB</StatNumber>
          </Stat> */}
        </SimpleGrid>

        <Divider my={5} />

        <Flex gap={5}>
          {/* <Box flex={1} height="300px">
            <Heading as="h4" size="md" mb={3}>
              Data Traffic Usage This Week
            </Heading>
            <ResponsiveContainer width="100%" height="100%">
              <LineChart data={dataLine}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Line
                  type="monotone"
                  dataKey="pv"
                  stroke="#8884d8"
                  activeDot={{ r: 8 }}
                />
                <Line type="monotone" dataKey="uv" stroke="#82ca9d" />
              </LineChart>
            </ResponsiveContainer>
          </Box> */}

          <Box flex={1} height="300px">
            <Heading as="h4" size="md" mb={3}>
              Active Device Pie Chart Situation
            </Heading>
            <ResponsiveContainer width="100%" height="100%">
              <PieChart>
                <Legend
                  formatter={(value, entry, index) => {
                    const color = COLORS[entry.value]; // COLORS is an object with your colors
                    return <span style={{ color }}>{value}</span>;
                  }}
                  wrapperStyle={{
                    bottom: 0,
                    right: 0,
                    fontWeight: "bold",
                    fontSize: "1em",
                  }}
                />
                <Pie
                  data={dataPie}
                  cx="50%"
                  cy="50%"
                  labelLine={false}
                  outerRadius={120}
                  fill="#8884d8"
                  dataKey="value"
                >
                  {dataPie.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={COLORS[index % COLORS.length]}
                    />
                  ))}
                </Pie>
                <Tooltip />
              </PieChart>
            </ResponsiveContainer>
          </Box>
        </Flex>
      </Box>
    </Flex>
  );
};

export default Dashboard;
