// AuthContext.js

import React, { createContext, useContext, useState, useEffect } from "react";

const AuthContext = createContext();

const isAuthenticated = () => {
  return localStorage.getItem("isLoggedIn") === "true";
  // Or if using a token
  // return !!localStorage.getItem('token');
};

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    console.log("Token from storage:", isAuthenticated());
    const token = localStorage.getItem("token");
    if (token) {
      // Assuming you're setting user to a simple object upon authentication
      // Adjust according to how you manage user details or tokens
      //   setUser({ isAuthenticated: true });
      setUser({ authenticated: true, token });
    }
    setIsLoading(false); // Set loading to false after checking token
  }, []);

  const login = (userData) => {
    localStorage.setItem("token", "true");
    // For tokens: localStorage.setItem('token', userData.token);
    setUser({ authenticated: true });
    // setUser(userData);
  };

  const logout = () => {
    localStorage.removeItem("token");
    // For tokens: localStorage.removeItem('token');
    setUser(null);
  };

  const value = { user, isLoading, login, logout };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => useContext(AuthContext);
