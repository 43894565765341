import React, { useState } from "react";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  NumberInput,
  NumberInputField,
  Select,
  useToast,
} from "@chakra-ui/react";

const OrderForm = () => {
  const [productType, setProductType] = useState("");
  const [quantity, setQuantity] = useState(1);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [addressLine1, setAddressLine1] = useState("");
  const [addressLine2, setAddressLine2] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [country, setCountry] = useState("");
  const [zip, setZip] = useState("");
  const toast = useToast();

  const validateAddress = () => {
    // Example validation: Ensure all required fields are filled
    return addressLine1 && city && state && country && zip;
  };

  const custid = localStorage.getItem("custid");

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateAddress()) {
      toast({
        title: "Validation Error",
        description: "Please fill in all required address fields",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    const payload = {
      productType,
      quantity,
      name,
      email,
      phone,
      address: {
        addressLine1,
        addressLine2,
        city,
        state,
        country,
        zip,
      },
    };

    try {
      const response = await fetch(
        "https://25i9r2nc9g.execute-api.us-east-2.amazonaws.com/submitOrder",
        {
          // Replace with your actual API Gateway URL
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        }
      );

      if (!response.ok) throw new Error("Network response was not ok.");

      const responseData = await response.json();
      console.log("Order submission response:", responseData);
      toast({
        title: "Order Submitted",
        description: "Your order has been successfully submitted!",
        status: "success",
        duration: 5000,
        isClosable: true,
      });

      // Reset the form here if needed
    } catch (error) {
      console.error("Order submission error:", error);
      toast({
        title: "Order Submission Error",
        description:
          "There was an error submitting your order. Please try again.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    // <Flex  direction="column" p={5} align="center" justify="center">
    <Box
      ml="350px"
      p={5}
      shadow="md"
      borderWidth="1px"
      flex="1"
      borderRadius="md"
    >
      <form onSubmit={handleSubmit}>
        <FormControl isRequired>
          <FormLabel htmlFor="productType">Product Type</FormLabel>
          <Select
            id="productType"
            placeholder="Select product"
            value={productType}
            onChange={(e) => setProductType(e.target.value)}
          >
            <option value="MTM-BPM">MTM BP Meter</option>
            <option value="MTM-OXM">MTM Oximeter</option>
          </Select>
        </FormControl>
        <FormControl isRequired mt={4}>
          <FormLabel htmlFor="quantity">Quantity</FormLabel>
          <NumberInput
            min={1}
            value={quantity}
            onChange={(valueString) => setQuantity(parseInt(valueString))}
          >
            <NumberInputField id="quantity" />
          </NumberInput>
        </FormControl>
        <FormControl isRequired mt={4}>
          <FormLabel htmlFor="name">Name</FormLabel>
          <Input
            id="name"
            placeholder="Full name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </FormControl>
        <FormControl isRequired mt={4}>
          <FormLabel htmlFor="email">Email</FormLabel>
          <Input
            id="email"
            type="email"
            placeholder="example@domain.com"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </FormControl>
        <FormControl isRequired mt={4}>
          <FormLabel htmlFor="phone">Phone Number</FormLabel>
          <Input
            id="phone"
            type="tel"
            placeholder="123-456-7890"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
          />
        </FormControl>
        <FormControl isRequired mt={4}>
          <FormLabel htmlFor="addressLine1">Address Line 1</FormLabel>
          <Input
            id="addressLine1"
            placeholder="Street, No."
            value={addressLine1}
            onChange={(e) => setAddressLine1(e.target.value)}
          />
        </FormControl>
        <FormControl mt={4}>
          <FormLabel htmlFor="addressLine2">
            Address Line 2 (Optional)
          </FormLabel>
          <Input
            id="addressLine2"
            placeholder="Apartment, suite, etc. (optional)"
            value={addressLine2}
            onChange={(e) => setAddressLine2(e.target.value)}
          />
        </FormControl>
        <FormControl isRequired mt={4}>
          <FormLabel htmlFor="city">City</FormLabel>
          <Input
            id="city"
            placeholder="City"
            value={city}
            onChange={(e) => setCity(e.target.value)}
          />
        </FormControl>
        <FormControl isRequired mt={4}>
          <FormLabel htmlFor="state">State/Province</FormLabel>
          <Input
            id="state"
            placeholder="State or Province"
            value={state}
            onChange={(e) => setState(e.target.value)}
          />
        </FormControl>
        <FormControl isRequired mt={4}>
          <FormLabel htmlFor="country">Country</FormLabel>
          <Input
            id="country"
            placeholder="Country"
            value={country}
            onChange={(e) => setCountry(e.target.value)}
          />
        </FormControl>
        <FormControl isRequired mt={4}>
          <FormLabel htmlFor="zip">Zip/Postal Code</FormLabel>
          <Input
            id="zip"
            placeholder="Zip or Postal Code"
            value={zip}
            onChange={(e) => setZip(e.target.value)}
          />
        </FormControl>
        <Button mt={4} width="full" type="submit" colorScheme="blue">
          Submit Order
        </Button>
      </form>
    </Box>
    // </Flex>
  );
};

export default OrderForm;
