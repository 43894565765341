import React from "react";
import { Box, VStack, Heading, Button, IconButton } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { NavLink } from "react-router-dom";
import { useAuth } from "../AuthContext";
import { CloseIcon } from "@chakra-ui/icons";

const Sidebar = () => {
  const navigate = useNavigate();
  const { logout } = useAuth();
  const handleLogout = async () => {
    await logout();
    navigate("/login");
  };
  return (
    <Box
      width={{ base: "250px", md: "350px" }}
      transition="width 0.2s ease-in-out"
      overflow="hidden"
      p={5}
      color="white"
      bg="blue.500"
      position="fixed"
      // {{"absolute", md: "relative" }}
      left={{ base: "0", md: "0" }}
      zIndex={2}
      h="100vh"
    >
      {/* Close Button for Mobile */}
      {/* {isOpen && (
        <IconButton
          aria-label="Close menu"
          icon={<CloseIcon />}
          onClick={onToggle}
          size="lg"
          position="absolute"
          top="1rem"
          right="1rem"
          zIndex="overlay"
        />
      )} */}

      <VStack align="stretch" spacing={5}>
        <Heading mb={8}>Med-Tech Match</Heading>

        {/* ... rest of your NavLink and Button components */}
        <NavLink to="/dashboard">
          {({ isActive }) => (
            <Button
              justifyContent="center" // Ensures horizontal centering
              alignItems="center" // Ensures vertical centering
              height="50px" // Or your preferred height
              width="100%" // Takes the full width of the parent container
              colorScheme="blue"
              isActive={isActive}
              bg={isActive ? "blue.700" : "blue.500"}
            >
              Dashboard
            </Button>
          )}
        </NavLink>

        <NavLink to="/device-info">
          {({ isActive }) => (
            <Button
              cjustifyContent="center" // Ensures horizontal centering
              alignItems="center" // Ensures vertical centering
              height="50px" // Or your preferred height
              width="100%" // Takes the full width of the parent container
              colorScheme="blue"
              isActive={isActive}
              bg={isActive ? "blue.700" : "blue.500"}
            >
              MTM Devices Info
            </Button>
          )}
        </NavLink>

        <NavLink to="/api">
          {({ isActive }) => (
            <Button
              justifyContent="center" // Ensures horizontal centering
              alignItems="center" // Ensures vertical centering
              height="50px" // Or your preferred height
              width="100%" // Takes the full width of the parent container
              colorScheme="blue"
              isActive={isActive}
              bg={isActive ? "blue.700" : "blue.500"}
            >
              API Info
            </Button>
          )}
        </NavLink>
        <NavLink to="/order">
          {({ isActive }) => (
            <Button
              justifyContent="center" // Ensures horizontal centering
              alignItems="center" // Ensures vertical centering
              height="50px" // Or your preferred height
              width="100%" // Takes the full width of the parent container
              colorScheme="blue"
              isActive={isActive}
              bg={isActive ? "blue.700" : "blue.500"}
            >
              Place an Order
            </Button>
          )}
        </NavLink>

        <Button colorScheme="red" onClick={handleLogout}>
          Logout
        </Button>
      </VStack>
    </Box>
  );
};

export default Sidebar;
