import React from "react";
import {
  Box,
  Button,
  Flex,
  Heading,
  Text,
  VStack,
  Link,
  Divider,
  Code,
  useClipboard,
  Input,
  HStack,
} from "@chakra-ui/react";

const APIPage = () => {
  const apiKey = localStorage.getItem("custid");
  const { hasCopied, onCopy } = useClipboard(apiKey);

  return (
    <Flex ml="350px" direction="column" p={5} align="center" justify="center">
      <VStack spacing={5} align="stretch">
        <Heading size="lg">MTM API Documentation</Heading>

        <Text fontSize="md">
          This document outlines the API provided for accessing live device
          data. Each client is identified by a unique customerid, which must be
          provided as a parameter in each API request.
        </Text>

        <Heading size="md">Base URL</Heading>
        <Code
          p={2}
        >{`https://aygqbopt0e.execute-api.us-east-2.amazonaws.com/`}</Code>

        <Divider my={4} />
        <HStack>
          <Input value={apiKey} isReadOnly placeholder="API Key" />
          <Button onClick={onCopy} colorScheme="blue">
            {hasCopied ? "Copied" : "Copy API Key"}
          </Button>
        </HStack>
        <Heading size="md">Endpoints</Heading>
        <Text fontSize="md">
          Get Latest Device Data
          <br />
          URL: /getLatestData
          <br />
          Method: GET
          <br />
          Parameters:
          <br />
          custid: The customer's unique identifier.
          <br />
          type: (Optional) The type of device data to retrieve, such as oximeter
          or bpmeter.
        </Text>

        <Text fontSize="md">
          <strong>Response:</strong>
          <br />
          200 OK: Successful retrieval of data.
          <br />
          Body: JSON array of device data objects. It will return all devices’
          last data registered on MTM for customerId.
          <br />
          400 Bad Request: Missing or incorrect parameters.
          <br />
          404 Not Found: custid does not match any records.
        </Text>

        <Heading size="md">Example Request</Heading>
        <Code
          p={2}
        >{`GET /getLatestData?custid=1234567890&type=oximeter`}</Code>

        <Heading size="md">Security</Heading>
        <Text fontSize="md">
          Requests are authenticated based on the custid parameter. Ensure you
          keep your custid secure and do not share it publicly.
        </Text>

        <Heading size="md">Rate Limiting</Heading>
        <Text fontSize="md">
          The API may have rate limiting applied. Clients are allowed a certain
          number of requests per minute. Exceeding this limit will result in a
          429 Too Many Requests response.
        </Text>

        <Heading size="md">Best Practices</Heading>
        <Text fontSize="md">
          Cache responses when possible to minimize API calls.
          <br />
          Validate the custid parameter before making API requests.
          <br />
          Monitor usage to adapt to the rate limiting and avoid service
          disruption.
        </Text>

        <Heading size="md">Support</Heading>
        <Text fontSize="md">
          For any special customization, issues or questions related to the API,
          please contact our support team at zenil@med-techmatch.com
        </Text>
      </VStack>
    </Flex>
  );
};

export default APIPage;
