import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../AuthContext";
import {
  Flex,
  Button,
  Center,
  FormControl,
  FormLabel,
  Input,
  VStack,
  Text,
  Spinner,
  Image,
  useColorModeValue,
} from "@chakra-ui/react";

const LoginPage = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false); // State to manage loading
  const navigate = useNavigate();
  const { login } = useAuth();
  const { user, isLoading } = useAuth();
  const formBackground = useColorModeValue("gray.50", "gray.700");

  useEffect(() => {
    if (!isLoading && user) {
      navigate("/dashboard");
    }
  }, [user, isLoading, navigate]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); // Start the loader
    try {
      const response = await fetch(
        "https://3nxd2xm1wi.execute-api.us-east-2.amazonaws.com/login",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email, password }),
        }
      );

      setLoading(false); // Stop the loader

      if (response.ok) {
        const data = await response.json();
        console.log("Login Successful", data);
        localStorage.setItem("token", "true");
        localStorage.setItem("custid", data.custid);
        login({ email });
        navigate("/dashboard");
      } else {
        alert("Login failed");
      }
    } catch (error) {
      setLoading(false); // Stop the loader in case of an error
      console.error("Login error:", error);
    }
  };

  return (
    <Flex height="100vh" alignItems="center" justifyContent="center">
      <Flex
        direction="column"
        background={formBackground}
        p={12}
        rounded={6}
        boxShadow="lg"
      >
        <Image
          width="350px"
          height="100px"
          objectFit="contain"
          src="https://med-techmatch.com/wp-content/uploads/2023/04/Med-Tech-Match-Logo.png" // Put your logo URL here
          alt="MTM"
        />

        {/* <Heading mb={6} fontSize="5xl" color="purple.600" textAlign="center">
          Med-Tech Match
        </Heading> */}
        {loading ? (
          <Center>
            <Spinner size="xl" />
          </Center>
        ) : (
          <VStack as="form" onSubmit={handleSubmit} spacing={4}>
            <FormControl isRequired>
              <FormLabel>Email</FormLabel>
              <Input
                type="email"
                placeholder="Enter your email"
                onChange={(e) => setEmail(e.target.value)}
              />
            </FormControl>
            <FormControl isRequired>
              <FormLabel>Password</FormLabel>
              <Input
                type="password"
                placeholder="Enter your password"
                onChange={(e) => setPassword(e.target.value)}
              />
            </FormControl>
            <Button
              type="submit"
              colorScheme="purple"
              width="full"
              mt={4}
              isLoading={loading}
              loadingText="Logging in..."
            >
              Login
            </Button>
          </VStack>
        )}
        <Text textAlign="center" mt={6}>
          © 2024 MTM
        </Text>
      </Flex>
    </Flex>
  );
};

export default LoginPage;
