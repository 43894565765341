import React from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "./AuthContext";

const ProtectedRoute = ({ children }) => {
  const { user, isLoading } = useAuth();

  if (isLoading) {
    return <div>Loading...</div>; // Or any suitable loader
  }
  //   if (!user) {
  //     return <Navigate to="/login" replace />;
  //   }

  //   return children;
  return user ? children : <Navigate to="/login" replace />;
};

export default ProtectedRoute;
