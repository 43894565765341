import React from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "./AuthContext"; // Adjust the import path as necessary

const RootRoute = () => {
  const { user } = useAuth(); // Now it's safe to call useAuth

  return user ? (
    <Navigate to="/dashboard" replace />
  ) : (
    <Navigate to="/login" replace />
  );
};

export default RootRoute;
