import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import { Flex, ChakraProvider, Box } from "@chakra-ui/react";
import { AuthProvider } from "./AuthContext";
import ProtectedRoute from "./ProtectedRoute";
import RootRoute from "./RootRoute";
import LoginPage from "./components/LoginPage";
import Dashboard from "./components/Dashboard";
import DeviceInfoPage from "./components/DeviceInfoPage";
import APIPage from "./components/APIPage";
import OrderPage from "./components/OrderPage";
import Sidebar from "./components/Sidebar";

function App() {
  return (
    <AuthProvider>
      <ChakraProvider>
        <Router>
          <Routes>
            <Route path="*" element={<Layout />} />
          </Routes>
        </Router>
      </ChakraProvider>
    </AuthProvider>
  );
}

const Layout = () => {
  const location = useLocation();
  const isLoginPage = location.pathname === "/login";

  return (
    <Flex>
      {/* Only render Sidebar if not on login page */}
      {!isLoginPage && <Sidebar />}

      <Box flex="1" p={{ base: "0", md: "6" }}>
        <Routes>
          <Route path="/" element={<RootRoute />} />
          <Route path="/login" element={<LoginPage />} />
          <Route
            path="/dashboard"
            element={
              <ProtectedRoute>
                <Dashboard />
              </ProtectedRoute>
            }
          />
          <Route
            path="/device-info"
            element={
              <ProtectedRoute>
                <DeviceInfoPage />
              </ProtectedRoute>
            }
          />
          <Route path="/api" element={<APIPage />} />
          <Route path="/order" element={<OrderPage />} />
          <Route path="*" element={<RootRoute />} />
        </Routes>
      </Box>
    </Flex>
  );
};

export default App;
